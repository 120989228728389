import React from 'react';
import { Link } from 'gatsby';

import Container from '../components/Container';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const H3 = (props) => <h3 className="mt-8 mb-4 text-lg font-bold" {...props} />;
const H4 = (props) => <h4 className="font-bold" {...props} />;

const P = (props) => <p className="mb-4" {...props} />;

const UL = (props) => <ul className="pl-8 mb-4 list-disc" {...props} />;

const A = (props) => <Link className="border-b" {...props} />;

const TermsPage = () => {
  return (
    <Layout>
      <SEO title="Terms" description="Read our Terms and Conditions." />
      <Container className="p-4 my-8 bg-white rounded-lg">
        <h2 className="mt-8 mb-4 text-2xl font-bold">
          Wolvido Terms of Service
        </h2>
        <P>
          These terms of service (these “Terms”) apply to the services provided
          by WIT Interactive LLC (“Wolvido,” “we,” “us” or “our”) via{' '}
          <A to="/">https://wolvido.com</A> (the “Site”) (collectively, the
          “Service”).
        </P>

        <section>
          <H3>Accepting these Terms</H3>

          <P>
            If you access or use the Service, it means you agree to be bound by
            all of the terms below. Please read all of the terms before you use
            the Service. If a term does not make sense to you, please let us
            know. If you don't agree to all of the terms below, you may not use
            the Service.
          </P>
        </section>

        <section>
          <H3>Changes to these Terms</H3>
          <P>
            We encourage you to review the Terms regularly to know about our
            practices. We may change these Terms from time to time. For example,
            we may change these Terms if we come out with a new feature. If we
            make changes, we will let you know by revising the date at the top
            of the policy and, in some cases, provide you with additional notice
            (such as sending you an email notification). Any changes we make to
            these Terms will be effective when we post them. If you use the
            Service after we have posted the changed Terms, then you have
            accepted the changes to these Terms.
          </P>
        </section>
        <section>
          <H3>Privacy Policy</H3>

          <P>
            For information about how we collect, use and share information
            about users of the Service, please see our{' '}
            <A to="/privacy/">Privacy Policy</A>.
          </P>
        </section>
        <section>
          <H3>Description of the Service</H3>
          <P>
            The Service enables you to post, store and make available to you and
            others videos, photographs, music, sounds, images, software, and
            other materials (“User Content”). For more information about the
            Service, please see our <A to="/help/faq/">FAQs</A>.
          </P>
        </section>
        <section>
          <H3>Creating Accounts</H3>
          <P>
            When you create a member account you agree to maintain the security
            of your password and accept all risk that someone may access your
            account without your permission. If you discover or suspect any
            Service security breaches, please let us know as soon as possible.
            You represent and warrant to us that all information that you
            provide in connection with your account is accurate, truthful,
            current and complete. Wolvido reserves the right to deny,
            deactivate, or terminate any account at our discretion.
          </P>
        </section>
        <section>
          <H3>Right to Use the Service</H3>
          <P>
            On the condition that you fully comply with these Terms, Wolvido
            grants you a limited, nonexclusive, non-transferable and revocable
            license to access and use the Service. Except as expressly
            authorized by these Terms, you may not (a) modify, disclose, alter,
            translate or create derivative works of the Service, (b) license,
            sublicense, resell, distribute, lease, rent, lend, transfer, assign
            or otherwise dispose of the Service, (c) disassemble, decompile or
            reverse engineer any of the software components of the Service, (d)
            copy, frame or mirror any part of the Service, (e) interfere with or
            disrupt the integrity or performance of the Service or (f) attempt
            to gain unauthorized access to the Service or its related systems or
            networks.
          </P>
        </section>
        <section>
          <H3>Prohibited Use of the Service</H3>

          <P>
            You may not post, store or otherwise make available on or through
            the Service any of the following:
          </P>
          <UL>
            <li>
              User Content that contains private information of any other person
              (including names, email addresses, phone numbers, Social Security
              numbers and financial information);
            </li>
            <li>
              User Content that is libelous, defamatory, abusive, offensive or
              hateful;
            </li>
            <li>
              User Content that is obscene, pornographic, indecent or sexually
              explicit, depicts graphic, excessive or gratuitous violence;
            </li>
            <li>
              User Content that is illegal, harmful or offensive or that would
              encourage, solicit, foster, glorify or provide instructions for
              any criminal or civil offense;
            </li>
            <li>
              User Content that promotes terrorism or other acts of violence;
            </li>
            <li>
              User Content that may infringe, misappropriate or violate any
              intellectual property rights, rights of privacy, rights of
              publicity or any other rights of others;
            </li>
            <li>
              User Content that may contain viruses, corrupted data or other
              harmful, disruptive or destructive files or code;
            </li>
            <li>
              User Content that, in Wolvido's judgment, is objectionable, may
              restrict or inhibit another from enjoying the Service or may
              expose Wolvido or users of the Service to harm or liability of any
              type; and/or
            </li>
            <li>
              User Content that you are contractually or legally required to
              keep confidential.
            </li>
          </UL>
          <P>
            Also, you may not do any of the following in connection with the
            Service or other users:
          </P>
          <UL>
            <li>
              Use the Service in any manner that could interfere with, disrupt,
              negatively affect or inhibit other users from fully enjoying the
              Service or that could damage, disable, overburden or impair the
              functioning of the Service;
            </li>
            <li>Collect any personal information about other users;</li>
            <li>
              Intimidate, threaten, stalk, bully or otherwise harass other
              users;
            </li>
            <li>Promote terrorism or other acts of violence;</li>
            <li>Post spam or commercial messages through the Service;</li>
            <li>
              Create an account or any post any User Content if you are not over
              13 years of age;
            </li>
            <li>
              Use automated means to submit or edit User Content (except as we
              otherwise permit);
            </li>
            <li>
              Use the Service for any illegal or unauthorized purpose or to
              engage in, encourage or promote any activity that is unlawful or
              that violates these Terms; and
            </li>
            <li>
              Circumvent or attempt to circumvent any filtering, security
              measures, rate limits or other features designed to protect the
              Service, its users, or third parties.
            </li>
          </UL>
          <P>
            Your use of the Service is at your own risk. Wolvido is not
            responsible or liable for the conduct of, or your interactions with,
            any other users (whether online or offline) or for any related
            damage or harm.
          </P>
          <P>
            When you post, store or otherwise make available User Content to the
            Service, you grant us a nonexclusive, royalty-free, perpetual and
            fully sublicensable right and license to use, reproduce, modify,
            create derivative works from, distribute, perform and display such
            User Content in order to provide the Service. Also, when you post,
            store or otherwise make available User Content to the Service, you
            grant us a nonexclusive, royalty-free, perpetual and fully
            sublicensable right and license to use, reproduce, modify, create
            derivative works from, distribute, perform and display such User
            Content for our business and marketing purposes.
          </P>
          <P>
            You represent and warrant that (a) you own and control all of the
            rights to all User Content that you post, store or otherwise make
            available to the Service, and (b) your posting, storing or otherwise
            making available of User Content does not violate these Terms and
            will not cause injury to any person or entity or violate,
            misappropriate or infringe any intellectual property rights, rights
            of privacy, rights of publicity or any other rights of any
            individual or entity.
          </P>
          <P>
            As a provider of the Service, Wolvido is not liable for User
            Content. Although we have no obligation to screen, edit or monitor
            User Content, we reserve the right, and have the discretion, to
            screen, edit or remove any User Content at any time, for any reason
            and without notice.
          </P>
        </section>
        <section>
          <H3>Reporting and Removal</H3>
          <P>
            Wolvido users may report User Content to Wolvido that he/she thinks
            violates these Terms, and Wolvido may remove that Content, suspend
            or terminate the account of the user who posted, stored or otherwise
            made available User Content and/or take additional action to enforce
            these Terms against such user.
          </P>
          <P>
            Also, in accordance with the Digital Millennium Copyright Act (DMCA)
            and other applicable law, Wolvido has adopted a policy of
            terminating, in appropriate circumstances and at our discretion,
            account holders who are deemed to be repeat infringers. We also may,
            at our discretion, limit access to the Service and terminate the
            accounts of any users who infringe any intellectual property rights
            of others, whether or not there is any repeat infringement.
          </P>
          <P>
            If you think that anything on the Service infringes upon any
            copyright that you own or control, you may file your notice by
            email:{' '}
            <a className="border-b" href="mailto:dmca@wolvido.com">
              dmca@wolvido.com
            </a>
            .
          </P>
          <P>
            Please see{' '}
            <a
              className="border-b"
              href="https://www.govinfo.gov/content/pkg/USCODE-2011-title17/pdf/USCODE-2011-title17-chap5-sec512.pdf"
              target="_blank"
              rel="noreferrer"
            >
              17 U.S.C. § 512(c)(3)
            </a>{' '}
            for the requirements of a proper notification. If you knowingly
            misrepresent that any material or activity is infringing, you may be
            liable for any damages, including costs and attorneys' fees. Wolvido
            or the alleged infringer incurs because we relied on the
            misrepresentation when removing or disabling access to the material
            or activity.
          </P>
        </section>
        <section>
          <H3>Feedback</H3>
          <P>
            Any suggestions, comments or other feedback you give us about the
            Service will be our confidential information. We are free to use,
            disclose, reproduce, license, distribute and exploit this feedback
            as we see fit, without compensation to you or any obligation or
            restriction because of any intellectual property rights or
            otherwise.
          </P>
        </section>
        <section>
          <H3>Wolvido Property</H3>
          <P>
            Wolvido’s name, trademarks, logos, and any other Wolvido product,
            service name, or slogan included in the Service are property of
            Wolvido and may not be copied, imitated, or used (in whole or in
            part) without Wolvido’s prior written consent. All information,
            materials and content of the Service, including text, graphics,
            data, formatting, graphs, designs, HTML, look and feel, photographs,
            music, sounds, images, software, videos, designs, typefaces, source
            and object code, format, queries, algorithms and other content is
            owned by Wolvido or is used with permission. Wolvido reserves all
            rights not expressly set forth in these Terms.
          </P>
        </section>
        <section>
          <H3>Third Party Materials</H3>
          <P>
            We may make third party content or services available on or through
            the Service as a convenience to our users (for example, links to
            third party websites) (“Third Party Materials”). Our users may also
            include Third Party Materials in the User Content that they post,
            store or otherwise make available to the Service. We do not control
            or endorse any Third Party Materials nor are we responsible for
            reviewing the accuracy of any Third Party Materials. Your business
            dealings or correspondence with third parties, and any terms,
            conditions, warranties or representations applicable to any Third
            Party Materials, are solely between you and the applicable third
            party. When you leave our Service, you should be aware that these
            Terms and all other Wolvido policies no longer govern your use of
            other websites and services.
          </P>
        </section>
        <section>
          <H3>Disclaimers</H3>
          <P>
            THE SERVICE AND ANY OTHER SERVICE AND CONTENT INCLUDED ON OR
            OTHERWISE MADE AVAILABLE TO YOU THROUGH THE SERVICE (INCLUDING THIRD
            PARTY MATERIALS) ARE PROVIDED TO YOU ON AN AS IS OR AS AVAILABLE
            BASIS WITHOUT ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND. WE
            DISCLAIM ANY AND ALL OTHER WARRANTIES AND REPRESENTATIONS (EXPRESS
            OR IMPLIED, ORAL OR WRITTEN) WITH RESPECT TO THE SERVICE AND CONTENT
            INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE SERVICE
            (INCLUDING THIRD PARTY MATERIALS) WHETHER ALLEGED TO ARISE BY
            OPERATION OF LAW, BY REASON OF CUSTOM OR USAGE IN THE TRADE, BY
            COURSE OF DEALING OR OTHERWISE.
          </P>
        </section>
        <section>
          <H3>Limitation of Liability</H3>
          <P>
            IN NO EVENT WILL WOLVIDO BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY
            DIRECT, SPECIAL, INDIRECT, INCIDENTAL, EXEMPLARY OR CONSEQUENTIAL
            DAMAGES OF ANY KIND ARISING OUT OF OR IN CONNECTION WITH THE SERVICE
            OR ANY OTHER SERVICE AND CONTENT INCLUDED ON OR OTHERWISE MADE
            AVAILABLE TO YOU THROUGH THE SERVICE (INCLUDING, BUT NOT LIMITED TO,
            THIRD PARTY MATERIALS AND USER CONTENT), REGARDLESS OF THE FORM OF
            ACTION, WHETHER IN CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE,
            EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF THESE DAMAGES OR
            ARE AWARE OF THE POSSIBILITY OF THESE DAMAGES.
          </P>
        </section>
        <section>
          <H3>Indemnification</H3>
          <P>
            You will defend, indemnify, and hold us harmless from and against
            any actual or threatened suits, actions, proceedings (at law or in
            equity), claims, damages, payments, deficiencies, fines, judgments,
            settlements, liabilities, losses, costs, and expenses (including
            reasonable attorneys' fees, costs, penalties, interest, and
            disbursements) arising from or related to any of your conduct with
            respect to the Service, the User Content you have posted, stored or
            otherwise made available via the Service, or any violation (or
            alleged violation) of these Terms or the rights of any third party
            by you or any person using your Wolvido account.
          </P>
        </section>
        <section>
          <H3>Changes to the Service</H3>
          <P>
            Wolvido reserves the right in our discretion to review, improve,
            change or discontinue, temporarily or permanently, the Service
            and/or any features, information, materials or content on the
            Service with or without providing notice to you. Wolvido will not be
            liable to you or any third party for any changes or discontinuance
            of the Service or any part of the Service.
          </P>
        </section>
        <section>
          <H3>Consent to Electronic Communications</H3>
          <P>
            By using the Service, you agree that we may communicate with you
            electronically regarding your use of the Service and that any
            notices, agreements, disclosures or other communications that we
            send to you electronically will satisfy any legal communication
            requirements, including that the communications be in writing. To
            withdraw your consent from receiving electronic notice, please
            notify us at{' '}
            <a href="mailto:support@wolvido.com" className="border-b">
              support@wolvido.com
            </a>
            .
          </P>
        </section>
        <section>
          <H3>Suspension and Termination</H3>
          <P>
            Wolvido may suspend or terminate your rights to access or use the
            Service (including the App) for any reason or for no reason at all
            and with or without notice at Wolvido's discretion. All of the terms
            of these Terms (excluding our license grants to you) will survive
            any termination or suspension. You may cancel your account at any
            time by contacting us at{' '}
            <a href="mailto:support@wolvido.com" className="border-b">
              support@wolvido.com
            </a>
            .
          </P>
        </section>
        <section>
          <H3>Governing Law; Arbitration</H3>
          <P>
            PLEASE READ THE FOLLOWING PARAGRAPHS CAREFULLY BECAUSE THEY REQUIRE
            YOU TO ARBITRATE DISPUTES WITH WOLVIDO AND LIMIT THE MANNER IN WHICH
            YOU CAN SEEK RELIEF FROM WOLVIDO.
          </P>
          <P>
            In the event of any controversy or claim arising out of or relating
            in any way to these Terms or the Service, you and Wolvido agree to
            consult and negotiate with each other and, recognizing your mutual
            interests, try to reach a solution satisfactory to both parties. If
            a settlement is not reached within a period of 60 days, then either
            party may, by notice to the other demand mediation under the
            mediation rules of the American Arbitration Association. Each party
            gives up such party’s right to litigate such party’s disputes and
            may not proceed to arbitration without first trying mediation, but
            you and Wolvido are NOT required to arbitrate any dispute in which
            either party seeks equitable and other relief from the alleged
            unlawful use of copyrights, trademarks, trade names, logos, trade
            secrets or patents. Whether the dispute is heard in arbitration or
            in court, you and Wolvido will not commence against the other a
            class action, class arbitration or other representative action or
            proceeding.
          </P>
          <P>
            If settlement is not reached within 60 days after service of a
            written demand for mediation, any unresolved controversy or claim
            will be resolved by arbitration in accordance with the rules of the
            American Arbitration Association. The language of all proceedings
            and filings will be English. The arbitrator will render a written
            opinion including findings of fact and law and the award and/or
            determination of the arbitrator will be binding on the parties, and
            their respective administrators and assigns, and will not be subject
            to appeal. Judgment may be entered upon the award of the arbitrator
            in any court of competent jurisdiction. The expenses of the
            arbitration will be shared equally by the parties unless the
            arbitration determines that the expenses will be otherwise assessed
            and the prevailing party may be awarded its attorneys' fees and
            expenses by the arbitrator. It is the intent of the parties that,
            barring extraordinary circumstances, arbitration proceedings will be
            concluded within 90 days from the date the arbitrator is appointed.
            The arbitrator may extend this time limit only if failure to do so
            would unduly prejudice the rights of the parties. Failure to adhere
            to this time limit will not constitute a basis for challenging the
            award. Consistent with the expedited nature of arbitration,
            pre-hearing information exchange will be limited to the reasonable
            production of relevant, non-privileged documents, carried out
            expeditiously.
          </P>
        </section>
        <section>
          <H3>General</H3>

          <H4>Third-Party Infrastructure</H4>
          <P>
            Notwithstanding any terms to the contrary in these Terms, you
            acknowledge and agree that Wolvido uses a third party hosting
            infrastructure in connection with the Service (“Third-Party
            Infrastructure”), the provider(s) of the Third-Party Infrastructure
            disclaim and make no representation or warranty with respect to such
            Third-Party Infrastructure, and Wolvido assumes no liability for any
            claim that may arise with respect to such Third-Party
            Infrastructure.
          </P>

          <H4>Electronic Communications</H4>
          <P>
            By using the Service, you agree that we may communicate with you
            electronically regarding your use of the Service and that any
            notices, agreements, disclosures or other communications that we
            send to you electronically will satisfy any legal communication
            requirements, including that the communications be in writing. To
            withdraw your consent from receiving electronic notice, please
            notify us at{' '}
            <a href="mailto:support@wolvido.com" className="border-b">
              support@wolvido.com
            </a>
            .
          </P>

          <H4>Severability</H4>
          <P>
            If any provision of these Terms is invalid, illegal, or incapable of
            being enforced by any rule of law or public policy, all other
            provisions of these Terms will nonetheless remain in full force and
            effect so long as the economic and legal substance of the
            transactions contemplated by these Terms is not affected in any
            manner adverse to any party. Upon such determination that any
            provision is invalid, illegal, or incapable of being enforced, the
            parties will negotiate in good faith to modify these Terms so as to
            effect the original intent of the parties as closely as possible in
            an acceptable manner to the end that the transactions contemplated
            hereby are fulfilled.
          </P>

          <H4>Force Majeure</H4>
          <P>
            Wolvido is not responsible for any failure to perform or delay
            attributable in whole or in part to any cause beyond its reasonable
            control including, but not limited to, acts of God (fire, storm,
            floods, earthquakes, etc.), acts of terrorism, civil disturbances,
            disruption of telecommunications, disruption of power or other
            essential services, interruption or termination of services provided
            by any service providers used by Wolvido, labor disturbances,
            vandalism, cable cut, computer viruses or other similar occurrences,
            or any malicious or unlawful acts of any third party.
          </P>
        </section>
      </Container>
    </Layout>
  );
};

export default TermsPage;
